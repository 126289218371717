import * as React from "react";
// import { useSpring, animated } from "@react-spring/web";

export const TopHeader: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isShowing, setIsShowing] = React.useState(false);
  const [isHiding, setIsHiding] = React.useState(false);

  return (
    <div id="site-topheader" className="site-topheader">
      <div className="container-xxl">
        <div className="row align-items-center">
          <div className="col-8 col-sm-6 col-md-3 header-icon-container">
            <div className="skip-main">
              <a href="#main">Skip to Main Content</a>
            </div>
            <a href="https://bumble.com/" title="Bumble">
              <img
                src="/the-buzz/logo/bumble-site-logo.svg"
                alt="Bumble Logo"
                className="bumble-logo"
              />
            </a>
          </div>
          {/* .col  */}
          <div className="col-4 col-sm-6 col-md-9 header-nav-container d-flex justify-content-end">
            <nav
              id="site-navigation"
              className="navbar navbar-expand-sm main-nav"
              aria-label="Main"
            >
              <ul id="menu-primary-menu" className="navbar-nav me-auto">
                <li
                  id="menu-item-13256"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13256 nav-item"
                >
                  <a href="https://bumble.shop/" className="nav-link">
                    The Shop
                  </a>
                </li>
                <li
                  id="menu-item-13337"
                  className="app-link menu-item menu-item-type-custom menu-item-object-custom menu-item-13337 nav-item"
                >
                  <a
                    target="_blank"
                    href="https://bumble.com/get-started?pid=bumble-new-buzz"
                    className="nav-link"
                  >
                    The App
                  </a>
                </li>
                <li
                  id="menu-item-13257"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13257 nav-item"
                >
                  <a
                    href="https://bumble.com/bumble-honey-ambassadors"
                    className="nav-link"
                  >
                    Ambassadors
                  </a>
                </li>
                <li
                  id="menu-item-13338"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13338 nav-item"
                >
                  <a href="https://bumble.events/" className="nav-link">
                    Events
                  </a>
                </li>
                <li
                  id="menu-item-13255"
                  className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-13255 nav-item active"
                >
                  <a
                    href="/the-buzz"
                    className="nav-link active"
                    aria-current="page"
                  >
                    The Buzz
                  </a>
                </li>
                <li
                  id="menu-item-13254"
                  className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13254 nav-item"
                >
                  <a href="https://bumble.com/about" className="nav-link">
                    About
                  </a>
                </li>
              </ul>{" "}
            </nav>
            {/* #site-navigation  */}
            <div className="mobile-menu">
              {/* @ts-ignore - href in button */}
              <button
                onClick={() => {
                  setIsShowing((isShown) => !isShown);
                  setTimeout(() => {
                    setIsOpen((isOpened) => !isOpened);
                    setIsShowing((isShown) => !isShown);
                  }, 500);
                }}
                className="navbar-toggler"
                type="button"
                aria-controls="offcanvasMenu"
                aria-label="Open Navigation Menu"
              >
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  role="graphics-symbol"
                  focusable="false"
                  width="24"
                  height="18"
                  viewBox="0 0 24 18"
                  fill="none"
                  xmlns="https://www.w3.org/2000/svg"
                >
                  <line
                    x1="0.807617"
                    y1="1.15394"
                    x2="23.8845"
                    y2="1.15394"
                    stroke="white"
                    strokeWidth="1.92308"
                  ></line>
                  <line
                    x1="0.807617"
                    y1="8.84632"
                    x2="23.8845"
                    y2="8.84632"
                    stroke="white"
                    strokeWidth="1.92308"
                  ></line>
                  <line
                    x1="0.807617"
                    y1="16.5385"
                    x2="23.8845"
                    y2="16.5385"
                    stroke="white"
                    strokeWidth="1.92308"
                  ></line>
                </svg>
              </button>
              <div
                className={`offcanvas offcanvas-start ${isOpen ? "show" : ""} ${isShowing ? "showing" : ""} ${isHiding ? "hiding" : ""}`}
                tabIndex={-1}
                id="offcanvasMenu"
                aria-labelledby="offcanvasMenuLabel"
              >
                <div className="offcanvas-header">
                  <button
                    onClick={() => {
                      setIsHiding((isHidden) => !isHidden);
                      setTimeout(() => {
                        setIsHiding((isHidden) => !isHidden);
                        setIsOpen((isOpened) => !isOpened);
                      }, 500);
                    }}
                    className="mobile-close"
                    type="button"
                    aria-label="Close"
                  >
                    <svg
                      xmlns="https://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                      fill="none"
                    >
                      <line
                        x1="1.33211"
                        y1="0.855393"
                        x2="14.1134"
                        y2="13.6366"
                        stroke="#FFC629"
                        strokeWidth="2"
                      ></line>
                      <line
                        x1="1.29289"
                        y1="13.6366"
                        x2="14.0741"
                        y2="0.855394"
                        stroke="#FFC629"
                        strokeWidth="2"
                      ></line>
                    </svg>
                  </button>
                </div>
                {/* .offcanvas-header  */}
                <div className="offcanvas-body">
                  {/* Search removed for now  */}
                  <nav
                    className="navbar navbar-expand-md mobile-nav navbar-light"
                    aria-label="Mobile Menu"
                  >
                    <ul id="menu-primary-menu-1" className="navbar-nav mr-auto">
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13256 nav-item">
                        <a href="https://bumble.shop/" className="nav-link">
                          <span className="menu-link-text">The Shop</span>
                        </a>
                      </li>
                      <li className="app-link menu-item menu-item-type-custom menu-item-object-custom menu-item-13337 nav-item">
                        <a
                          target="_blank"
                          href="https://bumble.com/get-started?pid=bumble-new-buzz"
                          className="nav-link"
                        >
                          <span className="menu-link-text">The App</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13257 nav-item">
                        <a
                          href="https://bumble.com/bumble-honey-ambassadors"
                          className="nav-link"
                        >
                          <span className="menu-link-text">Ambassadors</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13338 nav-item">
                        <a href="https://bumble.events/" className="nav-link">
                          <span className="menu-link-text">Events</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-2 current_page_item menu-item-13255 nav-item active">
                        <a
                          href="/the-buzz"
                          className="nav-link active"
                          aria-current="page"
                        >
                          <span className="menu-link-text">The Buzz</span>
                        </a>
                      </li>
                      <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13254 nav-item">
                        <a href="https://bumble.com/about" className="nav-link">
                          <span className="menu-link-text">About</span>
                        </a>
                      </li>
                    </ul>{" "}
                  </nav>

                  <div className="row mobile-social">
                    <div className="col">
                      <div
                        id="social-navigation"
                        className="social-nav"
                        role="presentation"
                      >
                        <h3 id="social-media" className="screen-reader-text">
                          Social Media
                        </h3>
                        <ul
                          id="menu-social-menu"
                          className="yikes-social-menu"
                          aria-labelledby="social-media"
                        >
                          <li
                            id="menu-item-13283"
                            className="yks-icon yks-icon-instagram menu-item menu-item-type-custom menu-item-object-custom menu-item-13283 nav-item"
                          >
                            <a
                              target="_blank"
                              href="https://instagram.com/bumble/"
                              className="nav-link"
                            >
                              <svg>
                                <use xlinkHref="#instagram"></use>
                              </svg>
                              <span className="screen-reader-text">
                                Instagram, opens in new tab
                              </span>
                            </a>
                          </li>
                          <li
                            id="menu-item-13284"
                            className="yks-icon yks-icon-facebook menu-item menu-item-type-custom menu-item-object-custom menu-item-13284 nav-item"
                          >
                            <a
                              target="_blank"
                              href="https://www.facebook.com/bumbleapp/"
                              className="nav-link"
                            >
                              <svg>
                                <use xlinkHref="#facebook"></use>
                              </svg>
                              <span className="screen-reader-text">
                                Facebook, opens in new tab
                              </span>
                            </a>
                          </li>
                          <li
                            id="menu-item-13282"
                            className="yks-icon yks-icon-twitter menu-item menu-item-type-custom menu-item-object-custom menu-item-13282 nav-item"
                          >
                            <a
                              target="_blank"
                              href="https://twitter.com/bumble"
                              className="nav-link"
                            >
                              <svg>
                                <use xlinkHref="#twitter"></use>
                              </svg>
                              <span className="screen-reader-text">
                                Twitter, opens in new tab
                              </span>
                            </a>
                          </li>
                          <li
                            id="menu-item-13347"
                            className="yks-icon yks-icon-pinterest menu-item menu-item-type-custom menu-item-object-custom menu-item-13347 nav-item"
                          >
                            <a
                              target="_blank"
                              href="https://www.pinterest.com/bumbleapp/"
                              className="nav-link"
                            >
                              <svg>
                                <use xlinkHref="#pinterest"></use>
                              </svg>
                              <span className="screen-reader-text">
                                Pinterest, opens in new tab
                              </span>
                            </a>
                          </li>
                        </ul>{" "}
                      </div>
                      {/* #social-navigation  */}
                    </div>
                    {/* .col  */}
                  </div>
                  {/* .row  */}
                </div>
                {/* .offcanvas-body  */}
              </div>
              {isOpen ? (
                <div
                  className={`offcanvas-backdrop fade ${isOpen ? "show" : ""}`}
                ></div>
              ) : null}
              {/* .offcanvas  */}
            </div>
            {/* .mobile-menu  */}
          </div>
          {/* .col.header-nav-container  */}
        </div>
        {/* .row  */}
      </div>
      {/* .container-xxl  */}
    </div>
  );
};

export default TopHeader;
